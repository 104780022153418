import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Categories,
  useGetProductsByCategoryQuery,
  DiningOccasion,
  Product
} from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { useRefetchOnYumEcommChanged } from '@/clientCore/helper/useRefetchOnYumEcommChanged';
import { NATIONAL_STORE_ID_FULL, OccasionApi } from '@/localization/constants';
import { selectOccasion } from '@/localization/selectors';
import { useGetProductsToHideQuery } from '@/api/reduxToolkit/hideProducts';
import { RootState } from '@/rootStateTypes';
import { PizzaMenuCategories, categorizePizzaProducts } from '../utils/pizzaCategories';
import { useNonYumDefaultSelectedOptionsHardcoding } from '../../../../hardcoding/utils/useNonYumDefaultSelectedOptionsHardcoding';
import { NewBadgeDecisionVariables as TavernNationalLaunchVariables } from '@/builders/pizza/setters/Toppings/ToppingsPicker/hooks/useNewToppingBadge';

function useFilterHiddenProducts(products: Product[] = []): Product[] {
  const { data: hiddenProducts } = useGetProductsToHideQuery();
  return useMemo(() => products.filter(
    (product) => !hiddenProducts?.products?.some((hiddenProduct) => product.id.includes(hiddenProduct))
  ), [products, hiddenProducts?.products]);
}

interface PizzaMenu extends PizzaMenuCategories {
  isLoading: boolean;
}

export const useGetPizzaMenu = (): PizzaMenu => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const cmsEnvId = useSelector((state: RootState) => state.domain.cmsEnv.id);
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [storeNumber, setStoreNumber] = useState<string | undefined>(undefined);

  const [{ variables: tavernNationalLaunchVariables }] = useDecision('cb-tavern_national_launch');

  // triggering a state update likely causes additonal re-renders which forces the query to re-run.
  useEffect(() => {
    setStoreNumber(storeDetails?.storeNumber ?? NATIONAL_STORE_ID_FULL);
  }, [storeDetails]);

  const {
    currentData,
    refetch,
    isUninitialized,
    isFetching
  } = useGetProductsByCategoryQuery({
    categoryId: Categories.PIZZA,
    environmentId: cmsEnvId,
    storeNumber,
    occasion: DiningOccasion[occasion as keyof typeof DiningOccasion],
    includeNutrition: true
  }, {
    skip: !storeNumber,
    refetchOnMountOrArgChange: true // TODO: Remove when authenticated users are supported when isYumEcomm = true
  });
  useRefetchOnYumEcommChanged({ refetch, isUninitialized }); // TODO: Remove when authenticated users are supported when isYumEcomm = true

  let products = useFilterHiddenProducts(currentData?.products);
  products = useNonYumDefaultSelectedOptionsHardcoding(products, isYumEcomm);
  return useMemo(() => {
    const tavernPizzaIds = (tavernNationalLaunchVariables as TavernNationalLaunchVariables)?.tavern_pizza_ids?.pizzas?.ids ?? [];
    return {
      ...categorizePizzaProducts(products, tavernPizzaIds),
      isLoading: isFetching
    };
  }, [tavernNationalLaunchVariables, products, isFetching]);
};

export default useGetPizzaMenu;
