import { useDispatch } from 'react-redux';
import { CartItem } from '@pizza-hut-us-development/client-core';
import { formatPrice } from '@/clientCore/cart/components/CartRail/components/CartContent/components/CartItem/helpers/cartItemHelpers';
import { Content, updateToast, openCartToast } from '@/common/CartToast/slices/Toast.slice';
import { PIZZA } from '@/domain/constants';
import decodeEntities from '@/graphql/helpers/decodeEntities';

export function useShowCartToast() {
  const dispatch = useDispatch();

  function showCartToast(item: CartItem) {
    const toastContent: Content = {
      productName: decodeEntities(item.name),
      productPrice: formatPrice(item.price),
      productQuantity: item.quantity
    };

    const analyticsObj = {
      item_index: 0,
      item_list: '',
      item_price: 0,
      item_name: item.name,
      item_category: PIZZA,
      item_position: ''
    };

    dispatch(updateToast({ itemAnalytics: analyticsObj, content: toastContent }));
    dispatch(openCartToast());
  }

  return { showCartToast };
}
