import { useMemo } from 'react';
import {
  Product, Modifier, Portion
} from '@pizza-hut-us-development/client-core';
import { SIZES } from '@/domain/constants';
import { isTavern } from '@/clientCore/menu/utils/pizzaCategories';
import { getSubtype, SIZE_SUBTYPE, CRUST_SUBTYPE } from '@/clientCore/menu/utils/pizzaNutrition';

/**
 * Selects a default modifier from the list of modifiers by comparing `internalName`
 * against a list of ideal internalNames and picking the highest ranking match.
 *
 * @param {Modifier[]} modifiers
 * @param {string[]} rankedInternalNames
 * @returns {Modifier | null}
 */
function selectDefaultModifierByRankedInternalName(modifiers: Modifier[], rankedInternalNames: string[]): Modifier | null {
  if (!modifiers.length) return null;
  const reversedRanks = [...rankedInternalNames].reverse(); // reverse order so that higher indexOf means better match
  const defaultModifier = modifiers.reduce((acc, cur) => (
    reversedRanks.indexOf(acc?.internalName ?? '') >= reversedRanks.indexOf(cur?.internalName ?? '') ? acc : cur
  ), modifiers[0]);
  return {
    ...defaultModifier,
    selected: true
  };
}

const sizePreferredRankOrder = ['large', 'medium', 'small'];
const getCrustRanks = (isTavernProduct: boolean) => (isTavernProduct ? ['tavern'] : []).concat('handtossedpizza');

function getSelectedOptions(options: Modifier[] = [], isTavernProduct = false): Modifier[] {
  const selectedOptions: Modifier[] = [];
  const sizeGroup = options.find((o) => o.name === SIZES);
  const size = selectDefaultModifierByRankedInternalName(sizeGroup?.modifiers ?? [], sizePreferredRankOrder);
  const crust = selectDefaultModifierByRankedInternalName(size?.modifiers ?? [], getCrustRanks(isTavernProduct));
  if (!size || !crust) {
    return selectedOptions;
  }
  selectedOptions.push({ ...size, subtype: SIZE_SUBTYPE });
  selectedOptions.push({ ...crust, subtype: CRUST_SUBTYPE });
  const nonSizeAndCrustGroups = options.filter((o) => o.name !== SIZES);
  nonSizeAndCrustGroups.forEach((group) => {
    group.modifiers?.filter((groupModifiers) => groupModifiers.selected).forEach((modifier) => {
      const portion = Portion.REGULAR;
      const subtype = getSubtype(group);
      if (modifier.modifiers?.length) {
        modifier.modifiers.forEach((m) => {
          if (m.selected) {
            selectedOptions.push({
              ...m,
              ...(subtype ? { subtype } : {}),
              portion: m.portion ?? portion
            });
          }
        });
      } else {
        selectedOptions.push({
          ...modifier,
          ...(subtype ? { subtype } : {}),
          portion: modifier.portion ?? portion
        });
      }
    });
  });

  return selectedOptions;
}

export function useNonYumDefaultSelectedOptionsHardcoding(products: Product[], isYumEcomm: boolean): Product[] {
  return useMemo(() => {
    if (isYumEcomm) return products;
    return products.map((product) => ({
      ...product,
      selectedOptions: getSelectedOptions(product.options, isTavern(product))
    }));
  }, [products, isYumEcomm]);
}
