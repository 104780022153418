export const glutenFreeDetails = (hideCloseIcon = false): ModalContent => ({
  body: 'Pizza Hut kitchens are not gluten-free environments. We cannot guarantee that our restaurant environment or any menu item will be completely free of gluten.',
  title: 'Gluten-free crust',
  cta: {
    text: 'GOT IT'
  },
  link: {
    linkUrl: 'https://www.pizzahut.com/index.php?gluten-free=#/gluten-free',
    linkDisplayText: 'More Details',
    linkIcon: true,
    linkTestId: 'gluten-free-more-details'
  },
  hideCloseIcon
});
